import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";

import "./Card.scss"

const WorkCard = ({title, path, tags, description, image}) => {
  return (
    <article className="work-card">
      {image && 
        <div className="work-card-image">
          {path ?
            <a href={path}>
              <GatsbyImage image={image.childImageSharp.gatsbyImageData} />
            </a>
          :
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} />
          }
        </div>
      }
      {tags &&
        <div className="work-card-tags">
          {tags.map(tag => <span key={tag}>{tag}</span>)}
        </div>
      }
      <div className="work-card-title">
        <h5><a href={path}>{title}</a></h5>
        <p>{description}</p>
      </div>
    </article>
  );
}

export default WorkCard
