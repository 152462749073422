import React from "react"
import PropTypes from "prop-types"

import Content from "src/components/Content"
import BackgroundOverlay from "src/components/BackgroundOverlay"
import GetConsultationButton from "src/components/GetConsultationButton"

import "./Consult.scss"

const Consult = ({ backgroundImage, description, title }) => (
  <section
    className="request-consultation-block"
    style={{ backgroundImage: backgroundImage && `url(${backgroundImage})` }}
  >
    <BackgroundOverlay />
    <div className="container">
      <div className="row>">
        <div className="col-md-7 offset-md-1">
          <h2>{title}</h2>
          <Content html={description} />
          <GetConsultationButton />
        </div>
      </div>
    </div>
  </section>
)

Consult.defaultProps = {
  title: "Ready for better?",
  description: `Tell us what problem you're facing or what you're looking for and we'll schedule a time to talk.`,
  backgroundImage: undefined,
}

Consult.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default Consult

