import React, { Component } from "react"

import Input from "src/components/forms/Input"
import Textarea from "src/components/forms/Textarea"
import "./Landing.scss"

class LandingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        version: "",
        name: "",
        email: "",
        company: "",
        phone: "",
        companysize: "",
        message: "",
      },
      formErrors: {
        name: "",
        email: "",
      },
      hasSubmitted: false,
      disabled: false,
      nameValid: false,
      emailValid: false,
      formValid: false,
      anonymousId: "",
    }
  }

  componentDidMount() {
    const anonId = typeof window.analytics.user === "function" ? window.analytics.user().anonymousId() : ""
    this.setState({ anonymousId: anonId })
  }

  fieldActive = (fieldName) => {
    const { formConfig } = this.props
    const fields = formConfig.fields ? formConfig.fields : "name, email, company"
    return fields.indexOf(fieldName) !== -1
  }

  validateFields = () => {
    const { formData, formErrors } = this.state
    const fieldValidationErrors = formErrors
    
    let nameValid = true
    let emailValid = true

    if(this.fieldActive("name")) {
      nameValid = formData.name.length > 0
      fieldValidationErrors.name = nameValid ? "" : "Please enter your name"
    }

    if(this.fieldActive("email")) {
      emailValid = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      fieldValidationErrors.email = emailValid ? "" : "Please enter a valid email"
    }

    this.setState({ 
      formErrors: fieldValidationErrors,
      nameValid,
      emailValid: Boolean(emailValid),
    }, () => this.validateForm())

    return nameValid && emailValid
  }

  validateForm = () => {
    const { nameValid, emailValid } = this.state
    this.setState({ formValid: nameValid && emailValid })
  }

  handleChange = e => {
    const { hasSubmitted } = this.state
    const { name, value } = e.target
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    }, () => { if(hasSubmitted) this.validateFields() })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ hasSubmitted: true })
    if(this.validateFields()) {
      const { formData } = this.state
      const { formConfig } = this.props
      const form = e.target
      window.analytics.identify({
        name: formData.name,
        email: formData.email,
        company: {
          name: formData.company
        },
        phone: formData.phone,
      })
      window.analytics.track("LandingForm", {
        formName: formConfig.name,
        formVersion: formConfig.version,
        message: formData.message,
      })
      form.submit()
    }
  }
  
  render() {
    const { disabled, formData, formErrors, formValid, hasSubmitted, anonymousId } = this.state
    const { name, company, companysize, email, phone, message } = formData
    const { formConfig } = this.props

    const disabledSubmit = hasSubmitted && !formValid

    const columns = formConfig.columns ? formConfig.columns : 12

    return (
      <div className="landing-form">
        <form 
          name={formConfig.name}
          method="POST" 
          data-netlify="true" 
          netlify-honeypot="companysize" 
          action="/thanks"
          onSubmit={this.handleSubmit}
        >
          <div className="hidden">
            <label htmlFor="companysize">Don&apos;t fill this out</label>
            <input 
              type="text" 
              name="companysize" 
              id="companysize" 
              value={companysize}
              onChange={this.handleChange}
              className="form-control form-control-lg" 
            />
            <input type="hidden" name="form-name" id="formName" value={formConfig.name} />
            <input type="hidden" name="version" value={formConfig.version} />
            <input type="hidden" name="user" value={anonymousId} />
          </div>
          {this.fieldActive("name") &&
            <div className="row">
              <div className={`col-md-${columns}`}>
                <div className="form-group">
                  <Input
                    label="Your Name *"
                    name="name"
                    onChange={this.handleChange}
                    placeholder="Richard Hendricks"
                    value={name}
                    disabled={disabled}
                    className="form-control form-control-lg"
                    error={formErrors.name}
                  />
                </div>
              </div>
            </div>
          }
          {this.fieldActive("company") &&
            <div className="row">
              <div className={`col-md-${columns}`}>
                <div className="form-group">
                  <Input
                    label="Company, Startup, or Product Name"
                    onChange={this.handleChange}
                    name="company"
                    value={company}
                    placeholder="Pied Piper"
                    disabled={disabled}
                    className="form-control form-control-lg"
                    error={formErrors.company}
                  />
                </div>
              </div>
            </div>
          }
          {this.fieldActive("email") &&
            <div className="row">
              <div className={`col-md-${columns}`}>
                <div className="form-group">
                  <Input
                    label="Email *"
                    type="email"
                    onChange={this.handleChange}
                    placeholder="richard@piedpiper.com"
                    name="email"
                    value={email}
                    disabled={disabled}
                    className="form-control form-control-lg"
                    error={formErrors.email}
                  />
                </div>
              </div>
            </div>
          }
          {this.fieldActive("phone") &&
            <div className="row">
              <div className={`col-md-${columns}`}>
                <div className="form-group">
                  <Input
                    label="Phone"
                    type="text"
                    onChange={this.handleChange}
                    name="phone"
                    value={phone}
                    disabled={disabled}
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
            </div>
          }
          {this.fieldActive("message") &&
            <div className="row">
              <div className={`col-md-${columns}`}>
                <div className="form-group">
                  <Textarea
                    label="How can we help?"
                    onChange={this.handleChange}
                    name="message"
                    value={message}
                    disabled={disabled}
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
            </div>
          }
          <button 
            type="submit" 
            className="btn btn-primary"
            disabled={disabledSubmit}
          >
            {formConfig.buttonLabel ? formConfig.buttonLabel : "Submit"}
          </button>
        </form>
      </div>
    )
  }
}

export default LandingForm
