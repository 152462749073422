import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import PageFooter from "src/components/page/Footer"
import Consult from "src/components/sections/Consult"
import Content from "src/components/Content"
import QuoteBlock from "src/components/QuoteBlock"
import ServiceCard from "src/components/services/Card"
import ProcessSteps from "src/components/ProcessSteps"
import WorkCard from "src/components/works/Card"

import "./roleTemplate.scss"

const RoleTemplate = ({data: {page: {frontmatter: {
  meta, hero, footer, main, detail, servicesSection, worksSection, consult, ourProcess,
}}, footerCards, services, works, quote}}) => {
  services = services.edges.filter(service => service.node.frontmatter.meta.categories.includes(servicesSection.category))
  works = works.edges.filter(work => work.node.frontmatter.meta.categories.includes(worksSection.category)).slice(0,2)

  return (
    <Layout meta={meta}>
      <div className="role-page">
        <PageHero {...hero} />
        {main &&
          <section className="role-description">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h2>{main.title}</h2>
                  <h4>{main.subtitle}</h4>
                  <Content html={main.description} />
                </div>
              </div>
            </div>
          </section>
        }

        {works && works.length > 0 &&
          <section className="role-works">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h2>{worksSection.title}</h2>
                </div>
              </div>
              
              <div className="works-grid">
                {works.map(work =>
                  <WorkCard
                    {...work.node.frontmatter.meta}
                    key={work.node.frontmatter.meta.title}
                  />
                )}
              </div>
            </div>
          </section>
        }

        {detail &&
          <section className="role-detail">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <article>
                    <div className="row">
                      <div className="col-md-6">
                        <h6>Common Challenges</h6>
                        <ul>
                          {detail.challenges.map(challenge => 
                            <li key={challenge} className="challenge">{challenge}</li>  
                          )}
                        </ul>
                        <br />
                      </div>
                      <div className="col-md-5 offset-md-1">
                        <h6>Types of Projects</h6>
                        <ul>
                          {detail.projects.map(project => 
                            <li key={project} className="project">{project}</li>  
                          )}
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>
        }

        <Consult 
          title={consult.title}
          description={consult.description}
        />

        {quote &&
          <section className="role-quote">
            <div className="container">
              <div className="row">
                <div className="col-10 offset-1">
                  <QuoteBlock {...quote.frontmatter} />
                </div>
              </div>
            </div>
          </section>
        }

        {services && services.length > 0 &&
          <section className="role-services">
            <div className="container">
              {services.map(service =>
                <ServiceCard {...service.node.frontmatter.meta} key={service.node.frontmatter.meta.title} />
              )}
            </div>
          </section>
        }

        {ourProcess &&
          <section className="role-process">
            <div className="container">
              <h2 className="h1">{ourProcess.title}</h2>
            </div>
            <ProcessSteps />
          </section>
        }

        <PageFooter {...footer} />
      </div>
    </Layout>
  )
}

export default RoleTemplate

export const pageQuery = graphql`
  query($path: String!, $footerCards: [String!], $quote: String!) {
    page: markdownRemark(frontmatter: {meta: {path: {eq: $path}}}) {
      html
      ...MetaFragment
      ...HeroFragment
      ...FooterFragment
      frontmatter {
        main {
          title
          description
        }
        servicesSection {
          category
          title
        }
        worksSection {
          category
          title
        }
        consult {
          title
          description
        }
      }
    }
    ...worksQuery
    ...servicesQuery
    ...quoteQuery
    ...footerCardsQuery
  }
`
