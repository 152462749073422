import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"

import Content from "src/components/Content"
import BackgroundImage from "gatsby-background-image"

import "./Card.scss"

const ServiceCard = ({ title, image, description, path, tags }) => {
  const cardClasses = classNames("service-card", tags)

  return (
    <Link
      to={path}
      index={title}
      className={cardClasses}
    >
      {image ? (
        <BackgroundImage
          className="card-body"
          fluid={image.childImageSharp.gatsbyImageData}
        >
          <div className="overlay"></div>
          <h3>{title}</h3>
          <Content html={description} />
        </BackgroundImage>
      ) : (
        <div className="card-body">
          <h3>{title}</h3>
          <Content html={description} />
        </div>
      )}
      <div className="card-footer">
        Learn more {"->"}
      </div>
    </Link>
  );
}

ServiceCard.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default ServiceCard
