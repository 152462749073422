import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"

import "./QuoteBlock.scss"

const QuoteBlock = ({ description, title, name, image, company, dark }) => (
  <div className={classNames("quote-block", { dark })}>
    <div className="container">
      <div className="row">
        <div className="col-10 offset-1">
          <blockquote>{description}</blockquote>
        </div>
      </div>
    </div>

    <div className="quoter">
      {image && <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={name} />}
      <p>
        {name}, {title}{company && `, ${company}`}
      </p>
    </div>
  </div>
)

QuoteBlock.defaultProps = {
  image: undefined,
  dark: false,
  company: undefined,
}

QuoteBlock.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.object,
  company: PropTypes.string,
  dark: PropTypes.bool,
}

export default QuoteBlock
