/* eslint-disable react/no-danger */
import React from "react"

import Content from "src/components/Content"
import LandingForm from "src/components/forms/Landing"
import InsightCarousel from "src/components/insights/Carousel"

import "./Footer.scss"

const Footer = ({cta, cards}) => (
  <>
    {(cta || cards) &&
      <section className="page-footer">
        <div className="container">
          {cta &&
            <div className="row footer-cta" id="cta">
              <div className="col-md-7">
                <h2>{cta.title}</h2>
                <Content html={cta.description} className="description" />
                {cta.alternative &&
                  <Content html={cta.alternative} className="alternative" />
                }
              </div>
              <div className="col-md-4 offset-md-1">
                <LandingForm formConfig={cta.form} />
                {cta.value &&
                  <p className="value">{cta.value}</p>
                }
              </div>
            </div>
          }
          {cards &&
            <div className="row footer-cards">
              <div className="container">
                <InsightCarousel cards={cards} />
              </div>
            </div>
          }
        </div>
      </section>
    }
  </>
)

Footer.defaultProps = {
}

Footer.propTypes = {
}

export default Footer
