import React from "react"
import { Link, graphql } from "gatsby"

import Content from "src/components/Content"

import img1 from "./collage-1.jpg"
import img2 from "./collage-2.jpg"
import img3 from "./collage-3.jpg"
import img4 from "./collage-4.jpg"
import img5 from "./collage-5.jpg"
import img6 from "./collage-6.jpg"
import img7 from "./collage-7.jpg"
import img8 from "./collage-8.jpg"
import img9 from "./collage-9.jpg"
import img10 from "./collage-10.jpg"
import "./index.scss"

const ProcessSteps = () => {
  const steps = [
    {
      title: "Discover",
      description: "Starting with a problem, our Product Managers help you discover root causes and alternative perspectives to see if Custom Software is the right solution, with a [__free consultation__](/chat).",
    },
    {
      title: "Plan",
      link: "/services/plan",
      description: "Our 3-day planning Sprint uses design thinking to reduce the risk of building new products. Participating in this process orients the entire team and aligns their efforts at hitting clearly defined goals with realistic commitments.",
    },
    {
      title: "Prototype",
      link: "/services/prototype",
      description: "Our Lean discovery of the right solution, we use validated results to build the right solution for the problem.",
    },
    {
      title: "Produce",
      link: "/services/produce",
      description: "Our Agile software development service is designed to build your solution right. By building confidence in results through consistency and transparency, our development cycles ultimately deliver an incredible product built the right way, the first time.",
    },
    {
      title: "Adoption & Beyond",
      description: "With the right custom software to meet your goals, we can continue to help your organization through adoption, change management, [product maintenance, and technology support](/services/protect) until you're ready for scale.",
    },
  ]

  const stepItems = steps.map(step => (
    <li 
      // data-sal="slide-up"
      // data-sal-duration="1000"
      // data-sal-easing="cubic-bezier(0.5, 0, 0, 1)"
      key={step.title}
    >
      <div className="bullet" />
      <h3>
        {step.link ?
          <Link to={step.link}>{step.title}</Link>
        :
          step.title
        }
      </h3>
      <Content html={step.description} />
    </li>
  ))

  const collage = [
    {img: img1, alt: "innovate", style: "div1", width: "620", height: "165"},
    {img: img2, alt: "modernize", style: "div2", width: "380", height: "325"},
    {img: img3, alt: "design", style: "div3", width: "240", height: "162"},
    {img: img4, alt: "streamline", style: "div4", width: "240", height: "162"},
    {img: img5, alt: "plan", style: "div5", width: "215", height: "220"},
    {img: img6, alt: "prototype", style: "div6", width: "405", height: "220"},
    {img: img7, alt: "produce", style: "div7", width: "405", height: "162"},
    {img: img8, alt: "software development", style: "div8", width: "405", height: "162"},
    {img: img9, alt: "web development", style: "div9", width: "212", height: "325"},
    {img: img10, alt: "custom solutions", style: "div10", width: "620", height: "165"},
  ]

  return (
    <div className="process-steps container">
      <div className="row">
        <div className="col-md-6 no-relative">
          <div className="collage">
            {collage.map(item => (
              <div
                className={item.style}
                key={item.alt}
              >
                <img 
                  src={item.img} 
                  alt={item.alt} 
                  loading="lazy" 
                  width={item.width} 
                  height={item.height}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <div className="timeline">
            <ul>{stepItems}</ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessSteps

export const query = graphql`
  fragment ourProcessQuery on MarkdownRemarkFrontmatter {
    ourProcess {
      title
    }
  }
`
