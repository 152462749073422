import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./Textarea.scss"

const Textarea = ({ label, name, value, onChange, disabled, className, required, error }) => {
  return (
    <div className={classNames({ "has-error": error.length > 0 })}>
      <label htmlFor={name}>{label}</label>
      <textarea 
        name={name}
        id={name}
        rows="5"
        onChange={onChange}
        className={className}
        required={required}
        disabled={disabled}
        value={value}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

Textarea.defaultProps = {
  required: false,
  disabled: false,
  error: ""
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
}

export default Textarea
